<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <SearchBar ref="searchBar" @search="onSearch" />
      </v-col>
      <v-col>
        <v-spacer></v-spacer>
      </v-col>
      <v-col class="shrink">
        <AddCourses
          :key="reload"
          @confirm="getAll()"
          @close="reload = !reload"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            {{ $store.getters.selectedLabel.name }}
          </v-card-title>
          <v-data-table
            mobile-breakpoint="1"
            :headers="headers"
            :items="data"
            :server-items-length="dataCount"
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :options.sync="options"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-blur
                    text
                    small
                    v-on="on"
                    @click="detachCourse(item.id)"
                  >
                    <v-icon color="red"> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Detach Course</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import AddCourses from '@/components/Labels/IA/AddCourses.vue';
export default {
  name: 'LabelCourses',
  components: {
    SearchBar,
    AddCourses,
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        const changed = Object.keys(newVal).some(
          (key) => newVal[key].toString() != oldVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      reload: false,
      search: '',
      data: [],
      dataCount: 0,
      options: {
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
        sortBy: ['name'],
        sortDesc: [false],
      },
      headers: [
        {
          text: 'Course Name',
          value: 'name',
          align: 'start',
          class: 'lightGrey',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          class: 'lightGrey',
          width: '50px',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    onSearch(search) {
      this.search = search;
      this.options.page = 1;
      this.updateUrl();
    },
    getAll() {
      this.search = this.search ?? '';

      const params = {
        labelId: this.$store.getters.selectedLabel.id,
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search,
        loaderText: 'loading...',
      };

      const url = 'get-label-courses?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.data = response.data.items;
          this.dataCount = response.data.count;
        })
        .catch((error) => error);
    },
    detachCourse(courseId) {
      const confirm = () => {
        let params = {
          courseId,
          labelId: this.$store.getters.selectedLabel.id,
          loaderText: 'Saving...',
        };

        let url = 'remove-course-from-label?format=json';

        this.$axios
          .post(url, params)
          .then(() => {
            this.getAll();
          })
          .catch((error) => error);
      };

      this.$root.showMessage(
        'Detach Label From Course Template',
        'Are you sure you want to detach label from this course template?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    getRouteQueries() {
      const query = this.$route.query;
      this.search = this.$route.query.search
        ? this.$route.query.search.trim()
        : '';
      this.options = this.$helpers.deserializeOptions(query, 'name');

      this.getAll();
    },
    updateUrl() {
      const params = this.$helpers.serializeOptions(
        this.options,
        'name',
        false,
      );

      if (this.search.trim().length) {
        params.search = this.search.trim();
      }

      this.$router
        .push({ name: this.$route.name, query: params })
        .catch(() => {});
    },
  },
  mounted() {
    this.getRouteQueries();
    this.$nextTick(() => {
      if (this.search != '') {
        this.$refs.searchBar.setSearch(this.search);
      }
    });
  },
};
</script>
